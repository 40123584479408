<template>
  <div class="main-view">
    <el-radio-group v-model="table.params.status" class="tabsWrap" @change="handleTabs">
      <el-radio-button :label="1">待处理({{table.init_total}})</el-radio-button>
      <el-radio-button :label="2">已处理({{table.deal_total}})</el-radio-button>
    </el-radio-group>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="客户名称" prop="merchant_id">
          <el-select v-model="table.params.merchant_id" filterable placeholder="请选择" @focus="getMerchantSel" clearable @change="onSearch">
            <el-option
              v-for="item in merchantArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下单账号" prop="phone">
          <el-input
            size="mini"
            v-model.trim="table.params.phone"
            placeholder="请输入下单账号"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <!-- 超级管理员才有地区查询条件 -->
        <el-form-item label="地区" prop="area" v-if="roleType == 1">
          <el-cascader
            v-model="table.params.area"
            :options="areaArr"
            :props="{ checkStrictly: true }"
            clearable
            filterable
            style="width:100%;"
            @change="onSearch">
          </el-cascader>
        </el-form-item>
        <el-form-item label="处理方式" prop="deal_type" v-if="table.params.status == 2">
          <el-select v-model="table.params.deal_type" clearable @change="onSearch">
            <el-option label="退货入库" :value="1"></el-option>
            <el-option label="退还供应商" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    
    <VTable
      has-pagionation
      :field="table.params.status == 1 ? field : field2"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="false"
      ref="tableDem"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:refund_type="{row}">
        <span>{{toStr({1:'质量问题',2:'发错货',3:'漏发少发'},row.refund_type)}}</span>
      </template>
      <template v-slot:refund_status="{row}">
        <span>{{toStr({1:'申请中',2:'已同意',3:'已拒绝'},row.refund_status)}}</span>
      </template>
      <template v-slot:deal_type="{row}">
        <span>{{row.deal_type == 1 ? '退货入库' : '退还供应商'}}</span>
      </template>
      <!-- 退货金额 = 退货数量 * 售价 -->
      <template v-slot:refund_money="{row}">
        <span>{{Math.round(parseFloat(row.refund_quantity * row.price)*100)/100}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button v-if="table.params.status == 1" type="text" @click="showEdit(row)">处理</el-button>
        <el-button type="text" @click="showDetail(row)">查看</el-button>
      </template>
    </VTable>

    <!-- 新增、编辑 -->
    <edit ref="edit" @refresh="getTable"></edit>
    <!-- 详情 -->
    <detail ref="detail"></detail>

  </div>
</template>

<script>
import Edit from './components/Edit.vue'
import Detail from './components/Detail.vue'
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
import { mapGetters } from 'vuex'
import { getStorage } from '@/storage'

export default {
  name: 'sendServiceList',
  components: {
    Edit,
    Detail,
    VTable,
  },
  mixins: [mixinTable],
  computed:{
    ...mapGetters(['areaArr'])
  },
  data() {
    return {
      roleType: getStorage('role_type'),// 1:超级管理员，2:管理员, 3：员工
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "order_no", label: "订单编号", width:'140', hidden: false },
        { name: "merchant_name", label: "客户名称", hidden: false},
        { name: "merchant_account", label: "下单账号", width:'120', hidden: false},
        { name: "store_name", label: "食堂名称", width:'120', hidden: false},
        { name: "good_name", label: "商品名称", showTooltip: true, hidden: false},
        { name: "price", label: "售价/元", showTooltip: true, hidden: false},
        { name: "spec_name", label: "规格", showTooltip: true, hidden: false},
        { name: "refund_quantity", label: "退货数量/件", showTooltip: true, hidden: false},
        { name: "refund_money", label: "退货金额/元", showTooltip: true, hidden: false},
        { name: "refund_time", label: "申请退货时间", showTooltip: true, hidden: false},
        { name: "refund_type", label: "退货原因", showTooltip: true, hidden: false},
        { name: "action", label: "操作",width: "100", fixed:"right", hidden: false }
      ],
      field2: [
        { name: "order_no", label: "订单编号", width:'140', hidden: false },
        { name: "merchant_name", label: "客户名称", hidden: false},
        { name: "merchant_account", label: "下单账号", width:'120', hidden: false},
        { name: "store_name", label: "食堂名称", width:'120', hidden: false},
        { name: "good_name", label: "商品名称", showTooltip: true, hidden: false},
        { name: "price", label: "售价/元", showTooltip: true, hidden: false},
        { name: "spec_name", label: "规格", showTooltip: true, hidden: false},
        { name: "refund_quantity", label: "退货数量/件", showTooltip: true, hidden: false},
        { name: "refund_money", label: "退货金额/元", showTooltip: true, hidden: false},
        { name: "refund_time", label: "申请退货时间", showTooltip: true, hidden: false},
        { name: "refund_type", label: "退货原因", showTooltip: true, hidden: false},
        { name: "refund_status", label: "审核结果", showTooltip: true, hidden: false},
        { name: "deal_type", label: "处理方式", showTooltip: true, hidden: false},
        { name: "action", label: "操作",width: "100", fixed:"right", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          status: 1,
          merchant_id: '',
          phone: '',
          area: [],
          province: '',
          city: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
        init_total: 0,
        deal_total: 0,
      },
      merchantArr: [],
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      let _area = this.table.params.area;
      if(_area.length == 1) {
        this.table.params.province = _area[0]
      } else if(_area.length == 2) {
        this.table.params.province = _area[0]
        this.table.params.city = _area[1]
      } else {
        this.table.params.province = '';
        this.table.params.city = '';
      }
      this.table.loading = true;
      this.$http.post('/admin/order/refundList', this.table.params).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.table.init_total = res.data.init_total;
          this.table.deal_total = res.data.deal_total;
        }
      })
    },
    // 新增、编辑
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if(!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 详情
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
  }
}
</script>

<style scoped>
.main-view {
  overflow: auto;
}
.main-view::-webkit-scrollbar {
  width:0px;
}
.main-view::-webkit-scrollbar-thumb {
  background: #8d8d8d;
  border-radius: 0px;
}
</style>
